<template>
  <li v-if="content.is_publishable" :class="classes">
    <button
      type="button"
      v-ripple="$utilities.getRippleOptions('dark')"
      @click="onClick">
      <span class="dp-capsule-list-item__thumbnail">
        <img :src="content.image_url" alt="" />
      </span>
      <span class="dp-capsule-list-item__title">{{ content.name }}</span>
    </button>
  </li>
</template>

<script>
import { defineComponent, inject, computed } from '@vue/composition-api';
export default defineComponent({
  props: {
    content: {
      type: Object,
      default: null
    }
  },

  setup(props) {
    const activate = inject('activateSelectedCapsuleDetail');
    const selectedCapsule = inject('selectedCapsule');
    const isActive = computed(
      () => selectedCapsule.value?.id === props.content?.id
    );

    const classes = computed(() => {
      return [
        'dp-capsule-list-item',
        isActive.value ? 'dp-capsule-list-item--active' : false
      ];
    });

    const onClick = () => {
      if (isActive.value) return;
      activate(props.content);
    };

    return {
      onClick,
      classes,
      isActive
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-capsule-list-item {
  margin: 0 -4px;
  width: 100%;
  height: 100%;
  font-size: 12px;
  line-height: 1.33;
  text-align: center;

  > button {
    display: grid;
    place-items: start center;
    gap: 16px;
    grid-template-rows: auto 1fr;
    padding: 8px 14px;
    width: 100%;
    height: 100%;
    color: variables.$dpBlack01;
  }

  &--active {
    > button {
      background-color: variables.$dpGreyF1;
    }
  }

  &__thumbnail {
    @include mixin.imageAspectRatio(82, 91);

    display: block;

    img {
      object-fit: contain;
    }
  }

  &__title {
    display: block;
    margin: 0 -6px;
    white-space: pre-wrap;
  }
}
</style>
