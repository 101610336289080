var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.content.is_publishable
    ? _c("li", { class: _vm.classes }, [
        _c(
          "button",
          {
            directives: [
              {
                name: "ripple",
                rawName: "v-ripple",
                value: _vm.$utilities.getRippleOptions("dark"),
                expression: "$utilities.getRippleOptions('dark')",
              },
            ],
            attrs: { type: "button" },
            on: { click: _vm.onClick },
          },
          [
            _c("span", { staticClass: "dp-capsule-list-item__thumbnail" }, [
              _c("img", { attrs: { src: _vm.content.image_url, alt: "" } }),
            ]),
            _c("span", { staticClass: "dp-capsule-list-item__title" }, [
              _vm._v(_vm._s(_vm.content.name)),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }